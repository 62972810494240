import React, { useState } from 'react';

import emailjs from 'emailjs-com';
import ThemedButton from './ThemedButton';
import { motion } from 'framer-motion';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('Sending...');

        try {
            await emailjs.send(
                'service_n30u4r8',
                'template_u6b6tgq',
                formData,
                "9NUSNiJSls_Ff-2kv"
            );

            // Reset form and status
            setFormData({ name: '', email: '', message: '' });
            setStatus('Message sent successfully!');
        } catch (error) {
            console.log(error);
            setStatus('Failed to send message. Please try again later.');
        }
    };

    return (

        <motion.div initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75 }} className="flex flex-col items-center ">


            <form onSubmit={handleSubmit} className="w-full max-w-lg outline outline-neutral-400 outline-1 p-6 rounded-lg shadow-lg">
                <p className="text-sm text-left text-neutral-400 mb-4">
                    Fill out the form to get started with <span className='text-white font-extrabold'>Forerunner</span>. Our team will reach out to schedule a personalized demo.
                </p>

                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2 text-white" htmlFor="name">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-3 border border-neutral-600 rounded bg-neutral-800 text-white focus:outline-none focus:border-neutral-500 autofill:bg-neutral-800"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2 text-white" htmlFor="email">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-3 border border-neutral-600 rounded bg-neutral-800 text-white focus:outline-none focus:border-neutral-500 autofill:bg-neutral-800"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-bold mb-2 text-white" htmlFor="message">
                        Message
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full p-3 border border-neutral-600 rounded bg-neutral-800 text-white focus:outline-none focus:border-neutral-500 autofill:bg-neutral-800"
                        rows="5"
                        required
                    />
                </div>
                <div className="flex justify-center w-full">
                    <ThemedButton
                        type="submit"
                        buttonText="Send"

                    />
                </div>

            </form>
            {status && <p className="mt-4 text-sm text-white">{status}</p>}
        </motion.div>


    );
};

export default Contact;
