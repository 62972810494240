import React, { useState, useEffect } from "react";

const TypewriterEffect = ({ texts, speed, lineDelay, preTextDelay }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [textIndex, setTextIndex] = useState(0);
    const [charIndex, setCharIndex] = useState(0);
    const [showCursor, setShowCursor] = useState(true);

    useEffect(() => {
        const cursorInterval = setInterval(() => setShowCursor(prev => !prev), 500);
        return () => clearInterval(cursorInterval);
    }, []);

    useEffect(() => {
        if (textIndex >= texts.length) {
            const resetTimeout = setTimeout(() => {
                setDisplayedText('');
                setTextIndex(0);
                setCharIndex(0);
            }, lineDelay);
            return () => clearTimeout(resetTimeout);
        }

        const currentText = texts[textIndex];
        const colonIndex = currentText.indexOf(':');

        if (charIndex === 0 && colonIndex !== -1) {
            setDisplayedText(prev => prev + `<span class="font-bold text-white ">${currentText.substring(0, colonIndex + 1)}</span>`);
            setCharIndex(colonIndex + 1);
            const delayTimeout = setTimeout(() => setCharIndex(colonIndex + 1), preTextDelay);
            return () => clearTimeout(delayTimeout);
        }

        if (charIndex < currentText.length) {
            const timeout = setTimeout(() => {
                setDisplayedText(prev => prev + currentText[charIndex]);
                setCharIndex(prev => prev + 1);
            }, speed);
            return () => clearTimeout(timeout);
        }

        const lineTimeout = setTimeout(() => {
            if (textIndex < texts.length - 1) {
                setDisplayedText(prev => prev + '<br/><br/>');
            }
            setTextIndex(prev => prev + 1);
            setCharIndex(0);
        }, lineDelay);
        return () => clearTimeout(lineTimeout);
    }, [charIndex, textIndex, texts, speed, lineDelay, preTextDelay]);

    return (
        <div className="text-xs 2xl:text-md text-neutral-400 inline-block m-2 text-left whitespace-pre-line">
            <span dangerouslySetInnerHTML={{ __html: displayedText }} />
            <span className={`blinking-cursor ${showCursor ? 'visible' : 'invisible'}`}>_</span>
        </div>
    );
};

export default TypewriterEffect;