import React from 'react';
import { motion } from 'framer-motion';
import Banner from '../components/Banner';
import SVGLoading from '../components/SVGLoading';

const VideoDemo = () => {
    return (
        <div className="bg-black flex flex-col h-screen w-screen">
            <Banner />
            <div className="flex flex-col h-full w-full p-16 justify-center items-center space-y-6">

                <motion.div
                    className='w-full outline outline-white p-4 md:p-8 rounded-xl max-w-5xl h-[90%]'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    <iframe
                        className="w-full h-full rounded-lg shadow-lg"
                        src="https://www.youtube.com/embed/0EsKdLXPFAs?rel=0&vq=hd1080"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </motion.div>
                <motion.div
                    className='text-gray-300 font-medium text-lg text-center px-4'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 1 }}
                >
                </motion.div>
            </div>
        </div>
    );
}

export default VideoDemo;
