import React from 'react';
import { motion } from 'framer-motion';

const SVGLoading = () => {
    const svgVariants = {
        hidden: { pathLength: 0 },
        visible: {
            pathLength: 1,
            transition: {
                duration: 2,
                ease: "easeInOut"
            }
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-black">
            <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 300 300"
                className="w-1/3 lg:w-48"
                initial="hidden"
                animate="visible"
            >
                <motion.g
                    transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                    variants={svgVariants}
                >
                    <motion.path
                        d="M 1770 2772 C 1655 2799 1388 2806 1275 2785 C 1062 2745 847 2647 685 2516 C 378 2266 206 1906 207 1510 C 207 1158 339 847 594 595 C 704 485 787 426 925 357 C 1341 149 1832 185 2230 453 C 2378 553 2538 734 2634 910 C 2792 1201 2826 1586 2721 1907 C 2677 2041 2606 2179 2520 2294 C 2512 2305 2462 2359 2410 2413 C 2237 2593 2024 2712 1770 2772 Z M 1346 2830 C 1384 2835 1469 2837 1535 2835 C 1886 2823 2174 2697 2426 2446 C 2620 2253 2735 2042 2792 1777 C 2822 1634 2822 1387 2793 1250 C 2734 985 2616 765 2431 579 C 2314 463 2220 395 2075 324 C 1422 0 630 270 310 925 C 205 1141 175 1272 176 1515 C 176 1714 191 1804 251 1969 C 419 2433 843 2767 1346 2830 Z"
                        fill="transparent"
                        stroke="#FFFFFF"
                        strokeWidth="20"
                        initial={{ pathLength: 0, rotate: 180 }}
                        animate={{ pathLength: 1, rotate: 0 }}
                        transition={{
                            duration: 2,
                            ease: "easeInOut",
                        }}
                    />
                    <motion.path
                        d="M1380 2595 c-8 -2 -49 -9 -90 -15 -152 -25 -325 -101 -462 -204 l-68
                        -50 0 -63 c0 -62 -1 -64 -61 -140 -60 -76 -127 -199 -162 -298 -54 -156 -64
                        -385 -23 -547 42 -167 109 -301 207 -419 36 -43 39 -52 39 -107 l0 -60 68 -52
                        c90 -70 265 -154 387 -186 90 -24 118 -27 275 -27 188 -1 247 8 385 60 102 38
                        261 123 285 153 3 3 20 16 38 29 30 21 32 26 32 82 0 53 4 65 33 102 17 23 34
                        44 37 47 13 12 50 70 82 130 189 352 147 799 -103 1109 -47 58 -49 62 -49 124
                        l0 63 -67 50 c-37 27 -72 51 -77 54 -6 3 -32 18 -57 33 -130 77 -315 126 -499
                        132 -74 2 -142 2 -150 0z m373 -64 c149 -39 307 -116 401 -194 35 -30 39 -38
                        45 -98 6 -60 11 -71 65 -139 66 -85 135 -222 167 -330 26 -93 38 -347 20 -440
                        -29 -146 -98 -298 -189 -419 -49 -64 -57 -81 -63 -133 -8 -70 -18 -83 -113
                        -145 -91 -60 -190 -105 -302 -139 -116 -34 -351 -45 -485 -21 -117 21 -265 78
                        -370 143 -111 69 -139 101 -139 160 0 39 -8 56 -66 137 -79 110 -123 194 -157
                        303 -95 300 -35 631 161 891 58 75 62 85 62 134 0 48 3 55 39 89 93 89 330
                        194 491 219 102 16 341 6 433 -18z"
                        fill="transparent"
                        stroke="#FFFFFF"
                        strokeWidth="20"
                        initial={{ pathLength: 0, rotate: -45 }}
                        animate={{ pathLength: 1, rotate: 0 }}
                        transition={{
                            delay: .5,
                            duration: 1.5,
                            ease: "easeInOut",

                        }}
                    />
                    <motion.path
                        d="M1097 1972 c-14 -15 -17 -40 -17 -126 0 -59 5 -116 10 -126 6 -11 28
                        -24 49 -30 35 -9 45 -19 85 -90 25 -43 46 -87 46 -96 0 -21 -78 -176 -94 -186
                        -6 -4 -21 -8 -33 -8 -13 0 -32 -9 -43 -20 -18 -18 -20 -33 -20 -129 0 -106 1
                        -110 26 -130 23 -18 41 -21 135 -21 59 0 119 5 131 11 26 11 52 50 123 184 27
                        50 57 102 67 118 l19 27 155 0 c200 0 194 -4 194 153 0 156 7 150 -204 157
                        -211 7 -211 24 1 28 206 3 203 1 203 152 0 79 -4 111 -16 128 l-15 22 -393 0
                        c-368 0 -394 -1 -409 -18z m803 -127
                        "
                        fill="transparent"
                        stroke="#FFFFFF"
                        strokeWidth="25"
                        initial={{ pathLength: 0, opacity: 0 }}
                        animate={{ pathLength: 1, opacity: 1 }}
                        transition={{
                            delay: .75,
                            duration: 1,
                            ease: "easeInOut",
                        }}
                    />
                </motion.g>
            </motion.svg>
        </div>
    );
};

export default SVGLoading;
