import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Banner from '../components/Banner';
import SVGLoading from '../components/SVGLoading';

const Login = ({ onSubmit }) => {
    const [password, setPassword] = useState('');

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(password);
    };

    return (
        <div className="bg-black flex flex-col h-screen w-screen">
            <Banner />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0 }}
                className="flex flex-col h-full w-full justify-center items-center space-y-6">
                <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-4">
                    <input
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="p-3 border border-neutral-600 rounded bg-neutral-800 text-white focus:outline-none focus:border-neutral-500"
                    />
                    <button
                        type="submit"
                        className="px-6 py-2 bg-neutral-600 text-white rounded hover:bg-neutral-500 focus:outline-none"
                    >
                        Submit
                    </button>
                </form>
            </motion.div>
        </div>
    );
}

export default Login;
