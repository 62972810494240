import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Banner from '../components/Banner';
import { Client } from '@gradio/client';
import Switch from '../components/Switch';

import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import Katex from '../components/Katex';

import katex from 'katex';
import 'katex/dist/katex.min.css';

const ChatDemo = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [writeMode, setWriteMode] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(true);

    const customSyntaxStyle = { border: '0px', margin: '0px' }

    const handleSend = async () => {
        if (input.trim()) {
            setMessages([...messages, <div key={messages.length} className="p-2 text-right rounded-lg text-sm bg-neutral-200 text-black self-end break-words text-wrap ml-auto max-w-[66%] md:max-w-[50%]">{input}</div>]);
            setInput('');
            setLoading(true);

            try {
                const client = await Client.connect("yamu1001/ForerunnerRAGBot", { hf_token: "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" });
                const result = await client.predict("/predict", {
                    query: input,
                    write_privileges: writeMode,
                });


                const botResponse = result.data;
                console.log(botResponse[0]);
                const formattedResponse = formatResponse(botResponse[0]);

                setMessages((prevMessages) => [
                    ...prevMessages,
                    <div key={prevMessages.length} className="flex items-start">
                        <img src="/Forerunner_Logo.png" alt="Forerunner Logo" className="w-8 h-8 mr-4" />
                        <div className={`rounded-lg text-sm text-neutral-200 break-words w-2/3 md:w-1/2`}>
                            {formattedResponse.map((element, index) => (
                                <React.Fragment key={index}>{element}</React.Fragment>
                            ))}
                        </div>
                    </div>
                ]);

            } catch (error) {
                console.error('Error fetching bot response:', error);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    <div key={prevMessages.length} className="p-2 rounded-lg w-1/4 bg-red-300 text-white">Error fetching bot response.</div>
                ]);
            } finally {
                setLoading(false);
            }
        }
    };

    const formatResponse = (text) => {
        const elements = [];
        const blockLatexStart = "```latex";
        const blockLatexEnd = "```";
        const blockPythonStart = "```python";
        const blockPythonEnd = "```";
        const blockMatlabStart = "```matlab";
        const blockMatlabEnd = "```";
        const displayLatexStart = "\\[";
        const displayLatexEnd = "\\]";
        const inlineLatexStart = "\\(";
        const inlineLatexEnd = "\\)";

        const extractAndPushLatex = (start, end, isBlock) => {
            const startIndex = text.indexOf(start);
            if (startIndex !== -1) {
                const endIndex = text.indexOf(end, startIndex + start.length);
                if (endIndex !== -1) {
                    const beforeLatex = text.slice(0, startIndex);
                    if (beforeLatex.trim()) {
                        elements.push(<span key={elements.length}>{beforeLatex}</span>);
                    }
                    const latex = text.slice(startIndex + start.length, endIndex).trim();
                    elements.push(
                        <Katex className="mr-2" key={elements.length} expression={latex} displayMode={isBlock} output={"html"} settings={{ throwOnError: false, strict: "ignore" }} />
                    );
                    text = text.slice(endIndex + end.length);
                    return true;
                }
            }
            return false;
        };

        const extractAndPushPython = () => {
            const startIndex = text.indexOf(blockPythonStart);
            if (startIndex !== -1) {
                const endIndex = text.indexOf(blockPythonEnd, startIndex + blockPythonStart.length);
                if (endIndex !== -1) {
                    const beforePython = text.slice(0, startIndex);
                    if (beforePython.trim()) {
                        elements.push(<span key={elements.length}>{beforePython}</span>);
                    }
                    const pythonCode = text.slice(startIndex + blockPythonStart.length, endIndex).trim();
                    elements.push(
                        <div key={elements.length} className="">
                            <div className="w-full bg-neutral-600 text-white text-xs px-3 py-2 rounded-t-lg">Python</div>
                            <SyntaxHighlighter showLineNumbers={true} className="rounded-b-lg m-0" language="python" style={atomDark} customStyle={customSyntaxStyle}>{pythonCode}</SyntaxHighlighter>
                        </div >
                    );
                    text = text.slice(endIndex + blockPythonEnd.length);
                    return true;
                }
            }
            return false;
        };

        const extractAndPushMatlab = () => {
            const startIndex = text.indexOf(blockMatlabStart);
            if (startIndex !== -1) {
                const endIndex = text.indexOf(blockMatlabEnd, startIndex + blockMatlabStart.length);
                if (endIndex !== -1) {
                    const beforeMatlab = text.slice(0, startIndex);
                    if (beforeMatlab.trim()) {
                        elements.push(<span key={elements.length}>{beforeMatlab}</span>);
                    }
                    const matlabCode = text.slice(startIndex + blockMatlabStart.length, endIndex).trim();
                    elements.push(
                        <div key={elements.length} className="">
                            <div className="w-full bg-neutral-600 text-white text-xs px-3 py-2 rounded-t-lg">MATLAB</div>
                            <SyntaxHighlighter showLineNumbers={true} className="rounded-b-lg m-0" language="matlab" style={atomDark} customStyle={customSyntaxStyle}>{matlabCode}</SyntaxHighlighter>
                        </div >
                    );
                    text = text.slice(endIndex + blockMatlabEnd.length);
                    return true;
                }
            }
            return false;
        };

        const extractAndPushUrl = () => {
            const urlRegex = /(https?:\/\/[^\s]+)/;
            const match = text.match(urlRegex);
            if (match) {
                const url = match[0];
                const beforeUrl = text.slice(0, text.indexOf(url));
                if (beforeUrl.trim()) {
                    elements.push(<span key={elements.length}>{beforeUrl}</span>);
                }
                elements.push(<a key={elements.length} href={url} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">{url}</a>);
                text = text.slice(text.indexOf(url) + url.length);
                return true;
            }
            return false;
        };

        while (text.length > 0) {
            const blockPythonIndex = text.indexOf(blockPythonStart);
            const blockMatlabIndex = text.indexOf(blockMatlabStart);
            const blockLatexIndex = text.indexOf(blockLatexStart);
            const displayLatexIndex = text.indexOf(displayLatexStart);
            const inlineLatexIndex = text.indexOf(inlineLatexStart);
            const newlineIndex = text.indexOf('\n');
            const urlIndex = text.search(/https?:\/\/[^\s]+/);

            const indices = [blockLatexIndex, blockPythonIndex, blockMatlabIndex, displayLatexIndex, inlineLatexIndex, newlineIndex, urlIndex].filter(index => index !== -1);
            const firstIndex = indices.length > 0 ? Math.min(...indices) : -1;

            if (firstIndex === blockLatexIndex && extractAndPushLatex(blockLatexStart, blockLatexEnd, true)) continue;
            if (firstIndex === blockPythonIndex && extractAndPushPython()) continue;
            if (firstIndex === blockMatlabIndex && extractAndPushMatlab()) continue;
            if (firstIndex === displayLatexIndex && extractAndPushLatex(displayLatexStart, displayLatexEnd, false)) continue;
            if (firstIndex === inlineLatexIndex && extractAndPushLatex(inlineLatexStart, inlineLatexEnd, false)) continue;
            if (firstIndex === urlIndex && extractAndPushUrl()) continue;

            // If a newline is next
            if (firstIndex === newlineIndex && newlineIndex !== -1) {
                const beforeNewline = text.slice(0, newlineIndex);
                elements.push(<ReactMarkdown key={elements.length} children={beforeNewline} components={{ p: 'span' }} />);
                elements.push(<br />);
                text = text.slice(newlineIndex + 1);
            } else {
                if (text.trim()) {
                    elements.push(<ReactMarkdown key={elements.length} children={text} components={{ p: 'span' }} />);
                }
                text = ''; // Ensure the loop exits after processing the remaining text
            }

            // Ensure progress is made in each iteration to avoid infinite loop
            if (firstIndex === -1) {
                break;
            }
        }

        return elements;
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75 }}
            className="flex flex-col h-screen bg-black text-white overflow-hidden overscroll-none"
        >
            <Banner />

            {sidebarVisible && <motion.div
                initial={{ opacity: 0, }}
                animate={{ opacity: sidebarVisible ? 1 : 0, }}
                exit={{ opacity: 0, }}
                transition={{ duration: 0.5 }}
                className={`absolute inset-0 md:hidden bg-neutral-950 transition-opacity duration-300 ${sidebarVisible ? 'bg-opacity-50' : 'bg-opacity-0 pointer-events-none'}`}
                onClick={() => setSidebarVisible(!sidebarVisible)}
            />}

            {/* Sidebar Toggle Button for small screens */}
            <div className="md:hidden flex justify-start absolute p-2 top-[8%]">
                <button
                    onClick={() => setSidebarVisible(!sidebarVisible)}
                    className="text-white bg-neutral-700 p-2 rounded-lg"
                >
                    <div className="flex flex-col space-y-1.5">
                        <span className="block w-6 h-0.5 bg-white"></span>
                        <span className="block w-6 h-0.5 bg-white"></span>
                        <span className="block w-6 h-0.5 bg-white"></span>
                    </div>
                </button>
            </div>

            <div className='flex flex-row justify-center items-center h-full w-full py-8 px-2 md:px-6 md:space-x-8'>

                {/* Sidebar */}
                <motion.div
                    initial={{ opacity: 0, }}
                    animate={{ opacity: sidebarVisible ? 1 : 0, }}
                    exit={{ opacity: 0, }}
                    transition={{ duration: 0.5 }}
                    className={`flex flex-col h-[60%] md:h-full w-9/12 md:w-4/12 bg-neutral-800 rounded-lg ${sidebarVisible ? 'absolute' : 'hidden'} md:relative md:flex`}
                    style={{ zIndex: 10 }}
                >

                    <h1 className='w-full text-center font-bold p-4 md:text-lg lg:text-xl xl:text-2xl'>Data Sources</h1>
                    <div className='flex flex-col space-y-4 px-4'>
                        {[
                            {
                                name: "Rocket Engine Specs",
                                description: "Confluence",
                                imgSrc: "/confluence.png",
                                link: "https://tryforerunner.atlassian.net/wiki/external/ZjZjMWNkOGUxZjY5NDg1N2E0MGFlZDc1ZGEyMzk5NmE"
                            },
                            {
                                name: "More Rocket Engine Specs",
                                description: "Confluence",
                                imgSrc: "/confluence.png",
                                link: "https://tryforerunner.atlassian.net/wiki/external/YWI3N2JkMDY4MGIyNGQ4MWFmYjYzZDM5YTdiZmNhNzE"
                            },
                            {
                                name: "Testing Data",
                                description: "Confluence",
                                imgSrc: "/confluence.png",
                                link: "https://tryforerunner.atlassian.net/wiki/external/MjQyZTEwMmJkMTNkNGYwYWFmODdjYjA1YzhmYmRlNDk"
                            }
                        ].map((source, index) => (
                            <a key={index} href={source.link} target="_blank" rel="noopener noreferrer" className='flex items-center bg-neutral-700 cursor-pointer transition-colors duration-300 hover:bg-neutral-600 p-2 rounded-lg'>
                                <img src={source.imgSrc} alt={`${source.name} Logo`} className='w-6 lg:w-8 mr-4' />
                                <div className=' overflow-hidden'>
                                    <span className='text-sm lg:text-md font-semibold'>{source.name}</span>
                                    <p className='text-xs lg:text-sm text-neutral-400 overflow-hidden'>{source.description}</p>
                                </div>
                            </a>
                        ))}
                    </div>

                    {/* Toggle Switch with Write Mode Indicator */}
                    <div className='flex flex-col space-y-2 justify-center px-4 items-center mt-auto mb-4 w-full'>
                        <div className='flex flex-row space-x-4 justify-center items-center mt-auto w-full'>
                            <span className='text-white w-auto font-bold'>{writeMode ? 'Write Mode On' : 'Write Mode Off'}</span>

                            <Switch className="" onClick={() => setWriteMode(!writeMode)} />
                        </div>
                        <p className='text-xs text-neutral-400 text-center '>Restricts search area. Lets Forerunner write to data source. CAUTION: may lead to complete overwrites.</p>
                    </div>

                </motion.div>

                {/* Messages + Chat Interface */}
                <div className='flex flex-col h-full  justify-between space-y-6 flex-none w-[100%] md:w-9/12 '>

                    {/* Messages */}
                    <div className="flex flex-col flex-grow space-y-4 mx-4 overflow-x-visible overflow-y-scroll h-0 no-scrollbar">
                        {messages.length === 0 ? (
                            <div className="flex flex-col items-center justify-center h-full text-center">
                                <div className="flex items-center mb-5 md:mb-10">
                                    <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold">Let's Get Started</h2>
                                </div>
                                <div className="flex lg:flex-container justify-center space-x-3 md:space-x-4">

                                    {/* Suggestion Bubbles */}
                                    {[
                                        {
                                            mainText: "Find Data",
                                            subText: "What is the most recent thrust on our Methalox engine?",
                                        },
                                        {
                                            mainText: "Perform Analysis",
                                            subText: "Solve for the mass flow rate on our cold gas thruster",
                                        },
                                        {
                                            mainText: "Update Data",
                                            subText: "Update the SRB design thrust with the new testing data",
                                        }
                                    ].map((bubble, index) => (
                                        <div
                                            key={index}
                                            className="p-2 md:p-3 bg-neutral-700 rounded-lg cursor-pointer w-1/3 transition-colors duration-300 hover:bg-neutral-600 "
                                            onClick={() => setInput(bubble.subText)}
                                        >
                                            <div className="text-sm md:text-md text-white pb-1 font-bold">{bubble.mainText}</div>

                                            <p className="text-xs md:text-sm text-neutral-400 break-words">{bubble.subText}</p>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        ) : (
                            messages.map((message, index) => (
                                <React.Fragment key={index}>{message}</React.Fragment>
                            ))
                        )}
                        {loading && (
                            <div className="p-2 rounded-lg bg-neutral-700 self-start">
                                <div className="loader" />
                            </div>
                        )}
                    </div>

                    {/* Message Input */}
                    <div className="flex flex-row justify-center w-full bg-black items-center">

                        <textarea
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSend();
                                }
                            }}
                            className="p-3 w-[50%] text-xs lg:text-sm rounded-l-lg h-full bg-neutral-800 text-white focus:outline-none resize-none"
                            placeholder="Type a message..."
                            rows="1"
                        />
                        <div
                            onClick={handleSend}
                            className="flex items-center justify-center w-[12%] md:w-[7.5%] text-xs lg:text-sm bg-neutral-700 h-full text-white rounded-r-lg cursor-pointer transition-colors duration-300 hover:bg-neutral-600"
                        >
                            Send
                        </div>

                    </div>
                </div>
            </div>
        </motion.div >
    );
};

export default ChatDemo;
