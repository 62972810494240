import React, { useEffect, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { AccumulativeShadows, RandomizedLight, Environment, ContactShadows } from '@react-three/drei';
import * as THREE from 'three';

const ThreeJSModel = ({ filePath, distance }) => {
    const modelRef = useRef();
    const controlsRef = useRef();

    return (
        <Canvas camera={{
            near: 0.1,
            far: 10000.0,
            position: [0, 0, distance],
            fov: 43
        }}>
            <Model filePath={filePath} modelRef={modelRef} controlsRef={controlsRef} />
            <color attach="background" args={['#000000']} />
            <ambientLight intensity={0.7} /> {/* Increase ambient light for better visibility */}
            <directionalLight position={[5, 5, 5]} intensity={1.2} castShadow /> {/* Enable shadows */}
            <AccumulativeShadows temporal frames={80} alphaTest={0.9} scale={10} position={[0, -0.5, 0]}>
                <RandomizedLight amount={10} radius={5} ambient={0.6} intensity={2.5} position={[5, 5, -10]} color="#ffffff" /> {/* Use white light for realistic shadows */}
            </AccumulativeShadows>
            <ContactShadows position={[0, -0.8, 0]} opacity={0.6} scale={10} blur={2.0} far={10} /> {/* Add contact shadows for depth */}
            <Environment preset="warehouse" />
            <OrbitControls
                ref={controlsRef}
                enableZoom={false} // Enable zoom for closer inspection
                enablePan={false} // Enable panning for better control
                maxPolarAngle={Math.PI / 2.55}
                minPolarAngle={Math.PI / 3}
                autoRotate
                autoRotateSpeed={2} // Slow down rotation for a more realistic feel
                rotateSpeed={0.5} // Adjust the rotation speed as needed
            />
        </Canvas>
    );
};

const Model = ({ filePath, modelRef, controlsRef }) => {
    const { scene } = useGLTF(filePath);
    const opacityRef = useRef(0); // Ref to track opacity

    useEffect(() => {
        // Traverse the scene to adjust material properties for a hyperrealistic look
        scene.traverse(function (object) {
            if (object.isMesh) {
                // Manipulate vertices to smooth out the model
                object.geometry.computeVertexNormals();
                object.material.color.set(0xaaaaaa); // Set to a slightly darker gray for a darker metal look
                object.material.transparent = true; // Enable transparency for fade-in effect
                object.material.opacity = 1; // Start with full transparency
                object.material.metalness = 1.0; // Maximize metalness for a more metallic surface
                object.material.roughness = 0.1; // Decrease roughness for a shinier surface
                object.material.flatShading = false; // Disable flat shading for smoother geometry
                object.material.envMapIntensity = 1.5; // Increase environment map intensity for more pronounced reflections
            }
        });

        if (controlsRef.current) {
            controlsRef.current.target.set(0, 0, 0); // Set target to the origin
            controlsRef.current.update();
        }
    }, [scene, controlsRef]);

    useFrame(() => {
        // Gradually increase opacity for fade-in effect
        if (opacityRef.current < 1) {
            opacityRef.current += 0.02; // Adjust the increment for desired fade-in speed
            scene.traverse((object) => {
                if (object.isMesh) {
                    object.material.opacity = opacityRef.current;
                }
            });
        }
    });

    return (
        <primitive
            object={scene}
            ref={modelRef}
            scale={[1.7, 1.7, 1.7]}
            position={[290, 50, -10]}
        />
    );
};

export default ThreeJSModel;
