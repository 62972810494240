import React from 'react';
import Banner from '../components/Banner';
import { motion } from 'framer-motion';

const NotFound = () => {
    return (
        <div className="bg-black flex flex-col h-screen w-screen">
            <Banner />
            <div className="flex flex-col h-full w-full justify-center items-center space-y-6">
                <motion.div
                    className='text-white font-extrabold text-7xl p-20'
                    initial={{ opacity: 0, }}
                    animate={{ opacity: 1, }}
                    transition={{ duration: 1 }}
                >
                    404: Page Not Found
                </motion.div>
                <motion.div
                    className='text-gray-300 font-semibold text-xl text-center px-4'
                    initial={{ opacity: 0, }}
                    animate={{ opacity: 1, }}
                    transition={{ duration: 1, delay: 0.5 }}
                >
                    "Speed produces nothing in manufacturing. Accuracy is the only straight line to greater production."
                </motion.div>
                <motion.div
                    className='text-gray-400 font-medium text-xl'
                    initial={{ opacity: 0, }}
                    animate={{ opacity: 1, }}
                    transition={{ duration: 1, delay: 1 }}
                >
                    - William Knudsen
                </motion.div>
                <motion.img
                    src="/404.png"
                    className="w-3/4 max-w-lg"
                    initial={{ opacity: 0, }}
                    animate={{ opacity: 1, }}
                    transition={{ duration: 1, delay: 1.5 }}
                />
            </div>
        </div>
    );
}

export default NotFound;