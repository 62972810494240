import React, { useState, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { TypeAnimation } from 'react-type-animation';


const Banner = () => {
    const navigate = useNavigate();
    const [isPending, startTransition] = useTransition();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleClick = (path) => {
        startTransition(() => {
            navigate(path);
        });
        setMenuOpen(false); // Close menu on navigation
    };

    const textVariants = {
        hover: { color: '#808080' },
        initial: { color: '#FFFFFF' }
    };

    return (
        <div className="bg-black flex items-center absolute justify-between py-2 lg:py-6  px-2 md:px-4 lg:px-6 w-[50%]">
            <div onClick={() => handleClick('/')} className="flex items-center cursor-pointer ">
                <img src={"/Forerunner_Logo.png"} alt="Company Logo" className="h-14 md:h-16 lg:h-20 w-auto select-none" />
                <span className="text-white text-md md:text-lg lg:text-2xl font-bold font-roboto ml-1.5 md:ml-3 select-none">

                </span>
            </div>
            {/* Routes */}
            <div className="hidden md:flex flex-row items-center justify-end w-6/12 lg:w-5/12 h-full">
                {/* <motion.span
                    onClick={() => handleClick('/video-demo')}
                    className="text-white cursor-pointer font-bold px-1 md:px-4 text-xs md:text-md lg:text-lg text-center"
                    variants={textVariants}
                    initial="initial"
                    whileHover="hover"
                >
                    Video Demo
                </motion.span>
                <motion.span
                    onClick={() => handleClick('/chat-demo')}
                    className="text-white cursor-pointer font-bold px-1 md:px-4 text-xs md:text-md lg:text-lg text-center"
                    variants={textVariants}
                    initial="initial"
                    whileHover="hover"
                >
                    Interactive Demo
                </motion.span> */}
                {/* <motion.span
                    onClick={() => handleClick('/contact')}
                    className="text-white cursor-pointer font-bold px-1 md:px-4 text-xs md:text-md lg:text-lg text-center"
                    variants={textVariants}
                    initial="initial"
                    whileHover="hover"
                >
                    Get Started
                </motion.span> */}
            </div>
            {/* <div className="md:hidden flex items-center">
                <div onClick={() => setMenuOpen(true)} className="cursor-pointer">
                    <span className="text-white text-2xl select-none">&#9776;</span>
                </div>
            </div>
            {menuOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-90 flex items-center justify-center z-50"
                >
                    <div onClick={() => setMenuOpen(false)} className="absolute top-4 right-4 text-white text-2xl cursor-pointer select-none">
                        &times;
                    </div>
                    {menuOpen && (
                        <div className="flex flex-col space-y-4 w-1/2 items-start ">
                            <div onClick={() => handleClick('/video-demo')} className="text-white cursor-pointer font-bold py-2 text-lg transition-colors duration-300 hover:text-neutral-300">
                                <TypeAnimation
                                    sequence={[
                                        "01_Video Demo",
                                        1000
                                    ]}
                                    speed={75}
                                    cursor={true}
                                    repeat={0}
                                    wrapper="span"
                                    className="text-white cursor-pointer font-bold py-2 text-lg transition-colors duration-300 hover:text-neutral-400 select-none"
                                />
                            </div>
                            <div onClick={() => handleClick('/chat-demo')} className="text-white cursor-pointer font-bold py-2 text-lg transition-colors duration-300 hover:text-neutral-400">
                                <TypeAnimation
                                    sequence={["", 250,
                                        "02_Interactive Demo",
                                        1000
                                    ]}
                                    speed={75}
                                    cursor={true}
                                    repeat={0}
                                    wrapper="span"
                                    className="text-white cursor-pointer font-bold py-2 text-lg transition-colors duration-300 hover:text-neutral-400 select-none"
                                />
                            </div>
                            <div onClick={() => handleClick('/contact')} className="text-white cursor-pointer font-bold py-2 text-lg transition-colors duration-300 hover:text-neutral-400">
                                <TypeAnimation
                                    sequence={["", 500,
                                        "03_Get Started",
                                        1000 // Waits 1s
                                    ]}
                                    speed={75}
                                    cursor={true}
                                    repeat={0}
                                    wrapper="span"
                                    className="text-white cursor-pointer font-bold py-2 text-lg transition-colors duration-300 hover:text-neutral-400 select-none"
                                />
                            </div>
                        </div>
                    )}
                </motion.div>
            )} */}

        </div>
    );
};

export default Banner;
