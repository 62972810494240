import React, { useState } from 'react';

const ToggleSwitch = ({ isOn, handleToggle, className }) => {
    return (
        <div
            className={`w-14 h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${isOn ? 'bg-neutral-700' : 'bg-neutral-400'} ${className}`}
            onClick={handleToggle}
        >
            <div
                className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ${isOn ? 'translate-x-6' : 'translate-x-0'}`}
            />
        </div>
    );
};

const Switch = ({ onClick, className }) => {
    const [isOn, setIsOn] = useState(false);

    const handleToggle = () => {
        setIsOn(!isOn);
        if (onClick) {
            onClick(!isOn);
        }
    };

    return (
        <div className={`flex items-center justify-center ${className}`}>
            <ToggleSwitch isOn={isOn} handleToggle={handleToggle} />
        </div>
    );
};

export default Switch;
