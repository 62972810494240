import React from 'react';
import '../index.css'; // Correct path to your CSS file

const ThemedButton = ({ buttonText, onClick }) => {
    return (
        <button
            className="rounded-md text-md 2xl:text-lg bg-white shadow-lg font-bold text-black py-2 px-4 hover:bg-neutral-400 transition duration-300" // Added transition for smooth hover effect
            onClick={onClick}
        >
            {buttonText}
        </button>
    );
};

export default ThemedButton;
