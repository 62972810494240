import React from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const Katex = ({ expression, className = '' }) => {
    const ref = React.useRef(null);

    React.useEffect(() => {
        if (ref.current) {
            try {
                katex.render(expression, ref.current, {
                    throwOnError: false, output: "html"
                });
            } catch (error) {
                console.error('Error rendering KaTeX:', error);
                ref.current.innerHTML = expression; // Fallback to raw expression
            }
        }
    }, [expression]);

    return <span className={`text-white inline ${className}`} ref={ref} />;
};

export default Katex;